/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Route } from 'react-router-dom';
import FooterArea from '../../components/FooterArea';
import LogoArea from '../../components/LogoArea';
import MainMenu from '../../components/MainMenu';

/**
 * PublicRoute Component
 * - Wraps a given component with common layout elements (header, menu, footer).
 * - Acts as a reusable route for pages with a consistent structure.
 *
 * Props:
 * - `component` (React.Component): The component to render for the route.
 * - `parentClass` (string): A CSS class applied to the parent wrapper for styling.
 * - `...rest`: Additional props passed to the underlying `Route` component.
 */
const PublicRoute = (props) => {
    const { component: Component, ...rest } = props;

    return (
        <div className={props.parentClass}>
            <div className="border_black" />

            {/* Header elements */}
            <LogoArea />
            <MainMenu />

            {/* Main route rendering */}
            <Route {...rest} render={(props) => <Component {...props} />} />

            {/* Footer element */}
            <FooterArea />
        </div>
    );
};

export default PublicRoute;
