/**
 * Utility functions for handling and appending CSS class names.
 * - `getNewClassName`: Filters and joins an array of class names into a single string.
 * - `appendClassName`: Combines a base class name with additional class names.
 */

/**
 * Filters out falsy values from an array of class names and joins them into a single string.
 * @param {string|string[]} newClassName - Class names as a string or an array.
 * @returns {string} - A single string of class names.
 */
function getNewClassName(newClassName) {
    if (!Array.isArray(newClassName)) return newClassName;

    return newClassName.filter((name) => name).join(' ');
}

export default function appendClassName(baseClassName, newClassName) {
    if (!newClassName)
        return (
            (Array.isArray(baseClassName)
                ? baseClassName.join(' ')
                : baseClassName) || ''
        );
    if (!baseClassName) return getNewClassName(newClassName) || '';
    return `${baseClassName} ${getNewClassName(newClassName)}`;
}
