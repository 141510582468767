export const FETCH_ARTICLES_REQUEST = 'FETCH_ARTICLES_REQUEST';
export const FETCH_ARTICLES_SUCCESS = 'FETCH_ARTICLES_SUCCESS';
export const FETCH_ARTICLES_FAILURE = 'FETCH_ARTICLES_FAILURE';
export const FETCH_ARTICLES_CITY_SUCCESS = 'FETCH_ARTICLES_CITY_SUCCESS';
export const FETCH_ARTICLES_HOMEPAGE_SUCCESS =
    'FETCH_ARTICLES_HOMEPAGE_SUCCESS';
export const FETCH_ARTICLES_CATEGORYPAGE_SUCCESS =
    'FETCH_ARTICLES_CATEGORYPAGE_SUCCESS';
export const FETCH_ARTICLES_CLEAN_UP = 'FETCH_ARTICLES_CLEAN_UP';
export const FETCH_ARTICLES_PUBLISHER_SECTION_SUCCESS =
    'FETCH_ARTICLES_PUBLISHER_SECTION_SUCCESS';
