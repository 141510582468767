import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { isMobileOnly, isTablet } from 'react-device-detect';
import rgOfferPlaceholderThumbnail from '../../doc/img/dummy_thumbnail.png';
import './index.scss';

/**
 * ArticlesSidebar Component
 * Displays a list of most-viewed articles and Squirrel News.
 * Includes a "Load More" button on mobile and tablet devices.
 */
const ArticlesSidebar = ({
    title,
    contentData,
    squirrelNewsArticles,
    link,
}) => {
    const history = useHistory();

    // Navigate to the full list page for the given title
    const handleClick = () => {
        history.push(`/${title.toLowerCase()}`);
    };

    return (
        <div className="most_widget3 padding20 white_bg border-radious5 mb30 sm-mt30">
            {/* Section Heading */}
            <div className="heading">
                <a
                    href={`${process.env.REACT_APP_BASE_PAGE_URL}/${link}`}
                    alt=""
                >
                    <h2 className="widget-title">{title || ''}</h2>
                </a>
            </div>
            <div className="space-20" />

            {/* Article List */}
            <div className="post_type2_carousel multipleRowCarousel pt12_wrapper nav_style1">
                {/* Render Squirrel News Articles */}
                {squirrelNewsArticles?.squirrelNews?.map((item) => (
                    <div
                        key={item.title}
                        className="single_post widgets_small type8 type17"
                    >
                        <div className="post_img">
                            <div className="img_wrap_2">
                                <a
                                    href={item.slug}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <img
                                        className="lazyLoad crop_image"
                                        src={
                                            item.image ||
                                            rgOfferPlaceholderThumbnail
                                        }
                                        alt="thumb"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="single_post_text">
                            <h4>
                                <a
                                    href={item.slug}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {item.title}
                                </a>
                            </h4>
                            <div className="meta2">
                                <p>
                                    {moment(item.date, 'DD.MM.YYYY').format(
                                        'LL'
                                    )}
                                    <img
                                        src="https://cdn1-2010d.kxcdn.com/de/wp-content/uploads/sites/2/2019/10/Squirrel_News-Logo-web.jpg"
                                        alt="Squirrel News Logo"
                                        className="squirrel-logo-sidebar"
                                    />
                                </p>
                            </div>
                            <div className="space-10" />
                        </div>
                    </div>
                ))}

                {/* Render Most-Viewed Content Articles */}
                {contentData &&
                    contentData.map((item) => (
                        <div
                            key={item.id}
                            className="single_post widgets_small type8 type17"
                        >
                            <div className="post_img">
                                <div className="img_wrap_2">
                                    <Link to={`/article/${item.slug}`}>
                                        <img
                                            className="lazyLoad crop_image"
                                            src={
                                                item.main_image
                                                    ? `${
                                                          process.env
                                                              .REACT_APP_CMS_URL_IMAGE
                                                      }${
                                                          item.main_image
                                                              .formats
                                                              ?.thumbnail
                                                              ?.url ||
                                                          item.main_image.url
                                                      }`
                                                    : rgOfferPlaceholderThumbnail
                                            }
                                            alt="thumb"
                                        />
                                    </Link>
                                </div>
                            </div>
                            <div className="single_post_text">
                                <h4>
                                    <Link to={`/article/${item.slug}`}>
                                        {item.title}
                                    </Link>
                                </h4>
                                <div className="meta2">
                                    <p>{moment(item.publishAt).format('LL')}</p>
                                </div>
                                <div className="space-10" />
                            </div>
                        </div>
                    ))}
            </div>

            {/* Show "Load More" button for mobile and tablet devices */}
            {(isMobileOnly || isTablet) && (
                <>
                    <div className="space-10" />
                    <button
                        type="button"
                        onClick={handleClick}
                        className="btn-sm search-button"
                    >
                        {`MEHR ${title}`}
                    </button>
                </>
            )}
        </div>
    );
};

export default ArticlesSidebar;
