import React, { useCallback, useState, useEffect } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import './index.scss';

// German locale configuration for the calendar
const calendarLocale = {
    firstDayOfWeek: 1,
    dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
    ],
    dayNamesShort: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ],
    monthNamesShort: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
    ],
    today: 'Heute',
    clear: 'Löschen',
};

// Generate an array of highlighted dates from the calendar content
const generateHighlightedDates = (articles) => {
    const dates = [];
    articles.forEach((article) => {
        if (Array.isArray(article.calendar)) {
            article.calendar.forEach((event) => {
                const startDate = moment(event.startDate);
                const endDate = moment(event.endDate);
                // Add all dates in the range to the array
                while (startDate.isSameOrBefore(endDate)) {
                    dates.push(startDate.format('YYYY-MM-DD'));
                    startDate.add(1, 'day');
                }
            });
        }
    });
    return dates;
};

const CalendarWidget = ({ calendarContent }) => {
    const history = useHistory();
    const [highlightedDates, setHighlightedDates] = useState([]);

    // Add German locale for the calendar
    addLocale('de', calendarLocale);

    // Update highlighted dates whenever the calendar content changes
    useEffect(() => {
        setHighlightedDates(generateHighlightedDates(calendarContent));
    }, [calendarContent]);

    // Custom date template for highlighting specific dates
    const dateTemplate = (event) => {
        if (event.day && event.month !== undefined) {
            const formattedDate = moment(
                `${event.year}-${event.month + 1}-${event.day}`,
                'YYYY-M-D'
            ).format('YYYY-MM-DD');

            const isHighlighted = highlightedDates.includes(formattedDate);

            return (
                <div
                    key={`${event.year}-${event.month + 1}-${event.day}`}
                    className={`custom-date ${
                        isHighlighted ? 'highlighted' : ''
                    }`}
                >
                    {event.day}
                </div>
            );
        }
        return null;
    };

    // Navigate to the calendar page on click
    const onClickCalendarWidget = useCallback(() => {
        history.push('/calendar');
    }, []);

    return (
        <div className={`follow_box widget mb30 `}>
            <div
                className="cta cursor_pointer"
                onClick={() => onClickCalendarWidget()}
            >
                <Calendar
                    panelClassName="panelCalendarWidget"
                    locale="de"
                    inline
                    dateTemplate={dateTemplate}
                />
            </div>
        </div>
    );
};

export default CalendarWidget;
