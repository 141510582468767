import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PublicRoute from '../_PublicRoute';
import HomePage from '../HomePage';
import CategoryPage from '../CategoryPage';
import InfoPage from '../InfoPage';
import NotFoundPage from '../NotFoundPage';
import ArticlePage from '../ArticlePage';
import OfferPage from '../OfferPage';
import OffersPage from '../OffersPage';
import mockMenu from '../../mockdata/menu.json';
import mockPages from '../../mockdata/pages.json';
import CookieDeclarationPage from '../CookieDeclarationPage';
import WissenPage from '../WissenPage';
import GtTipsPage from '../GtTipsPage';
import NewsPage from '../NewsPage';
import NewSearchPage from '../NewSearchPage';
import PflegePage from '../PflegePage';
import CalendarPage from '../CalendarPage';
import RetreatPage from '../RetreatPage';

/**
 * Dynamically selects and renders the appropriate page component based on the menu name.
 *
 * @param {string} pageName - Name of the page to render.
 * @param {Array} categories - Categories associated with the page (if any).
 * @param {Object} props - Props passed to the page component.
 * @returns {JSX.Element} - The selected page component.
 */
const selectPage = (pageName, categories, props) => {
    if (pageName === 'suchportal') {
        return <NewSearchPage {...props} />;
    }
    if (pageName === 'angebote') {
        return (
            <OffersPage
                {...props}
                menuName={pageName}
                categories={categories}
            />
        );
    }
    if (pageName === 'gesundekommune') {
        return <WissenPage menuName={pageName} />;
    }
    if (pageName === 'gesundheitstipps') {
        return <GtTipsPage menuName={pageName} />;
    }
    if (pageName === 'news') {
        return <NewsPage menuName={pageName} />;
    }
    if (pageName === 'pflege') {
        return <PflegePage menuName={pageName} />;
    }
    if (pageName === 'retreat') {
        return <RetreatPage menuName={pageName} />;
    }
    return (
        <CategoryPage {...props} menuName={pageName} categories={categories} />
    );
};

/**
 * Routes Component
 * Defines the application's routing logic, dynamically rendering pages based on the URL.
 */
const Routes = () => {
    return (
        <Switch>
            {/* Home Page Route */}
            <PublicRoute
                exact
                home_style={2}
                parentClass="theme-3 theme3_bg"
                path="/"
                component={HomePage}
            />

            {/* Routes for mock menu items */}
            {mockMenu.map((item) => (
                <PublicRoute
                    key={item.id}
                    exact
                    path={item.link}
                    home_style={2}
                    parentClass="theme-3 theme3_bg"
                    component={(props) =>
                        selectPage(
                            item.linkText.split(' ').join(''),
                            item.categories,
                            props
                        )
                    }
                />
            ))}

            {/* Routes for mock pages */}
            {mockPages.map((item) => (
                <PublicRoute
                    key={item.id}
                    exact
                    path={item.link}
                    home_style={2}
                    parentClass="theme-3 theme3_bg"
                    component={(props) => (
                        <InfoPage {...props} name={item.name} />
                    )}
                />
            ))}

            {/* Article Page Route */}
            <PublicRoute
                exact
                path="/article/:slug"
                home_style={2}
                parentClass="theme-3 theme3_bg"
                component={ArticlePage}
            />

            {/* Offer Page Route */}
            <PublicRoute
                exact
                path="/offer/:slug"
                home_style={2}
                parentClass="theme-3 theme3_bg"
                component={OfferPage}
            />

            {/* Search Portal Page Route */}
            <PublicRoute
                exact
                path="/searchportal"
                home_style={2}
                parentClass="theme-3 theme3_bg"
                component={(props) => <NewSearchPage {...props} />}
            />

            {/* Cookie Declaration Page Route */}
            <PublicRoute
                exact
                path="/cookie-declaration"
                parentClass="theme-3 theme3_bg"
                component={CookieDeclarationPage}
            />

            {/* Calendar Page Route */}
            <PublicRoute
                exact
                path="/calendar"
                parentClass="theme-3 theme3_bg"
                component={CalendarPage}
            />

            {/* Catch-All Route for Not Found Page */}
            <Route path="*" component={NotFoundPage} />
        </Switch>
    );
};

export default Routes;
