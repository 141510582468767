export const FETCH_OFFERS_REQUEST = 'FETCH_OFFERS_REQUEST';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';
export const FETCH_OFFERS_CLEAN_UP = 'FETCH_OFFERS_CLEAN_UP';

export const FETCH_OFFERS_FOR_PAGE_REQUEST = 'FETCH_OFFERS_FOR_PAGE_REQUEST';
export const FETCH_OFFERS_FOR_PAGE_SUCCESS = 'FETCH_OFFERS_FOR_PAGE_SUCCESS';
export const FETCH_OFFERS_FOR_PAGE_FAILURE = 'FETCH_OFFERS_FOR_PAGE_FAILURE';
export const FETCH_OFFERS_FOR_PAGE_CLEAN_UP = 'FETCH_OFFERS_FOR_PAGE_CLEAN_UP';

export const UPDATE_OFFERS_PAGE_START_PARAM = 'UPDATE_OFFERS_PAGE_START_PARAM';
