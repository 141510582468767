import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

/**
 * ScrollToTop Component
 * Ensures the page scrolls to the top whenever the route changes.
 * Useful for improving user experience when navigating between pages.
 */
function ScrollToTop({ history }) {
    useEffect(() => {
        // Listen for route changes and scroll to the top
        const unlisten = history.listen(() => {
            window.scrollTo(0, 0);
        });

        // Cleanup the listener on component unmount
        return () => {
            unlisten();
        };
    }, [history]);

    return null; // No visual rendering
}

export default withRouter(ScrollToTop);
