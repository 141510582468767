import axios from 'axios';
import {
    FETCH_CALENDAR_REQUEST,
    FETCH_CALENDAR_SUCCESS,
    FETCH_CALENDAR_FAILURE,
} from '../constants/calendarTypes';

const fetchCalendarRequest = () => {
    return {
        type: FETCH_CALENDAR_REQUEST,
    };
};

const fetchCalendarSuccess = (calendar) => {
    return {
        type: FETCH_CALENDAR_SUCCESS,
        payload: calendar,
    };
};

const fetchCalendarFailure = (error) => {
    return {
        type: FETCH_CALENDAR_FAILURE,
        payload: error,
    };
};

export const fetchCalendar = (startOfMonth, endOfMonth) => {
    return (dispatch) => {
        dispatch(fetchCalendarRequest);
        axios
            .get(
                `${process.env.REACT_APP_CMS_URL}/contents/published?${
                    startOfMonth
                        ? `filters[calendar][startDate]][$gte]=${startOfMonth}`
                        : ''
                }&${
                    endOfMonth
                        ? `filters[calendar][endDate][$lte]=${endOfMonth}`
                        : ''
                }`
            )
            .then((response) => {
                const calendar = response.data;
                dispatch(fetchCalendarSuccess(calendar));
            })
            .catch((error) => {
                const errorMsg = error.message;
                dispatch(fetchCalendarFailure(errorMsg));
            });
    };
};

export default fetchCalendar;
