import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isMobileOnly } from 'react-device-detect';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import moment from 'moment';
import ReactMarkdown from 'react-markdown';
// eslint-disable-next-line camelcase
import {
    fetchArticle,
    fetchArticleCleanUp,
    fetchArticles,
    fetchArticlesCleanUp,
} from '../../store/actions';
import FontAwesome from '../../components/uiStyle/FontAwesome';
import rgOfferPlaceholderMedium from '../../doc/img/dummy_medium.png';
import Metadata from '../../components/Metadata';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AdserverLeaderboard from '../../components/AdserverLeaderboard';
import LatestContent from '../../components/LatestContent';
import Socials from '../../components/Socials';

/**
 * Helper function to process article content
 * - Adds `target='_blank'` to all `<a>` tags.
 * - Removes `srcset` and `sizes` attributes from `<img>` tags for consistency.
 */
const replaceContent = (data) => {
    // Add target='_blank' to all href links
    let content = data.replace(/href/g, "target='_blank' href");

    // Use a regular expression to find all <img> tags and replace them
    content = content.replace(/<img[^>]+>/g, (imgTag) => {
        // Remove srcset and sizes attributes
        const newImgTag = imgTag
            .replace(/\s+srcset="[^"]*"/g, '')
            .replace(/\s+sizes="[^"]*"/g, '');
        return newImgTag;
    });

    return content;
};

/**
 * Adds "http://" to URLs that lack a protocol for proper linking.
 */
const addhttp = (url) => {
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        const newUrl = `http://${url}`;
        return newUrl;
    }
    return url;
};

/**
 * Transforms image URLs to include the base CMS path.
 */
const transform = (data) => {
    const imageLink = `${process.env.REACT_APP_CMS_URL_IMAGE + data}`;
    return imageLink;
};

/**
 * Custom renderer for markdown links
 * - Ensures links open in a new tab with proper security attributes.
 */
const LinkRenderer = ({ href, children }) => {
    return (
        <a href={href} target="_blank" rel="noopener noreferrer">
            {children}
        </a>
    );
};

/**
 * Determines the meta description for the article
 * - Uses the article description if available.
 * - Falls back to the first category name if description is missing.
 */
const getMetaDescription = (description, categories) => {
    let metaDescription = '';
    if (description) {
        metaDescription = description;
    } else if (categories && categories.length > 0) {
        metaDescription = categories[0].name;
    }
    return metaDescription;
};

/**
 * Retrieves image dimensions for meta tags
 * - Loads the image and sets its natural dimensions in the state.
 */
const getMeta = (url, width, setWidth, height, setHeight) => {
    const img = new Image();
    img.addEventListener('load', function () {
        setWidth(this.naturalWidth);
        setHeight(this.naturalHeight);
    });
    img.src = url;
    return { width, height };
};

/**
 * ArticlePage Component
 * - Displays an article with metadata, content, author information, and related content.
 * - Handles fetching and cleanup of article data and related articles.
 */
const ArticlePage = ({
    articleData,
    stateArticles,
    fetchArticle,
    fetchArticleCleanUp,
    fetchArticles,
    fetchArticlesCleanUp,
}) => {
    const { trackPageView } = useMatomo();
    // Track page view
    useEffect(() => {
        if (articleData?.article) {
            const { title, slug } = articleData.article;

            // Ensure title and slug are defined to avoid runtime errors
            if (title && slug) {
                trackPageView({
                    documentTitle: `Regio Health News - ${title}`,
                    href: `${process.env.REACT_APP_BASE_PAGE_URL}/article/${slug}`,
                });
            }
        }
    }, [articleData?.article]);

    const { slug } = useParams();
    useEffect(() => {
        fetchArticle(slug);
        return () => fetchArticleCleanUp();
    }, [slug]);

    useEffect(() => {
        const { article } = articleData;
        if (article) {
            fetchArticles({ limit: 8, menuName: article.menu, slug });
        }

        return () => fetchArticlesCleanUp();
    }, [articleData, fetchArticles, fetchArticlesCleanUp, slug]);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const article = articleData.article || null;

    return (
        article && (
            <>
                <Metadata
                    title={article.title}
                    description={getMetaDescription(
                        article.description,
                        article.categories
                    )}
                    image={
                        // eslint-disable-next-line no-nested-ternary
                        article.main_image &&
                        article.main_image.formats &&
                        article.main_image.formats.small
                            ? process.env.REACT_APP_CMS_URL_IMAGE +
                              article.main_image.formats.small.url
                            : article.main_image
                            ? process.env.REACT_APP_CMS_URL_IMAGE +
                              article.main_image.url
                            : ''
                    }
                    imageSize={
                        article.main_image &&
                        article.main_image.formats &&
                        article.main_image.formats.small
                            ? getMeta(
                                  process.env.REACT_APP_CMS_URL_IMAGE +
                                      article.main_image.formats.small.url,
                                  width,
                                  setWidth,
                                  height,
                                  setHeight
                              )
                            : ''
                    }
                    url={`${process.env.REACT_APP_BASE_PAGE_URL}/article/${slug}`}
                />
                <div className="archives post post1">
                    <div className="space-20" />
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-10 col-lg-8 m-auto">
                                <div className="single_post_heading">
                                    <h1>{article.title}</h1>
                                </div>
                                <div className="space-20" />
                                <div className="single_post_description">
                                    <p>{article.description}</p>
                                </div>
                                <div className="img_wrap">
                                    <img
                                        src={
                                            article.main_image
                                                ? `${
                                                      process.env
                                                          .REACT_APP_CMS_URL_IMAGE +
                                                      article.main_image.url
                                                  }`
                                                : rgOfferPlaceholderMedium
                                        }
                                        alt="thumb"
                                        className="border-radious7"
                                        effect="blur"
                                    />
                                </div>

                                <div className="space-20" />

                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <Socials
                                            content={article}
                                            type="article"
                                        />
                                    </div>
                                    <div className="col-12 col-lg-6 text-left text-lg-right">
                                        <div className="author ">
                                            {article.profile &&
                                            article.profile.website ? (
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={
                                                        article.profile &&
                                                        article.profile.website
                                                            ? addhttp(
                                                                  article
                                                                      .profile
                                                                      .website
                                                              )
                                                            : '#'
                                                    }
                                                >
                                                    {article.author}
                                                    {article.profile &&
                                                    article.profile.company
                                                        ? ` / ${article.profile.company}`
                                                        : ''}
                                                </a>
                                            ) : (
                                                <p className="company-no-link">
                                                    {' '}
                                                    {article.author}
                                                    {article.profile &&
                                                    article.profile.company
                                                        ? ` / ${article.profile.company}`
                                                        : ''}
                                                </p>
                                            )}
                                            <ul>
                                                <li>
                                                    {moment(
                                                        article.publishAt
                                                    ).format('LL')}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-20" />
                                <div className="single_post_content">
                                    {!article.bodyIsHtml && (
                                        <ReactMarkdown
                                            className="markdownContainer margin"
                                            transformImageUri={(data) =>
                                                transform(data)
                                            }
                                            renderers={{ link: LinkRenderer }}
                                            source={article.content}
                                        />
                                    )}
                                    {article.bodyIsHtml && article.content && (
                                        <div
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: replaceContent(
                                                    article.content
                                                ),
                                            }}
                                        />
                                    )}
                                </div>
                                {article.documents && (
                                    <div className="documents">
                                        <ul className="none">
                                            {article.documents.data.map(
                                                (doc) => (
                                                    <li key={doc.id}>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href={
                                                                process.env
                                                                    .REACT_APP_CMS_URL +
                                                                doc.url
                                                            }
                                                        >
                                                            <FontAwesome name="file-text" />
                                                            <span>
                                                                {doc.name}
                                                            </span>
                                                        </a>
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="space-50" />
                        {stateArticles.length > 0 && (
                            <div className="row">
                                <div className="col-12 col-md-10 col-lg-8 m-auto">
                                    <LatestContent
                                        contentData={stateArticles}
                                        type="article"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {!isMobileOnly && <AdserverLeaderboard />}
            </>
        )
    );
};

/**
 * Maps Redux state to component props.
 * - Provides the article data and related articles from the store.
 */
const mapStateToProps = (state) => {
    return {
        articleData: state.article,
        stateArticles: state.articles.articles,
    };
};

/**
 * Maps Redux actions to component props.
 * - Connects fetch and cleanup actions for articles.
 */
const mapDispatchToProps = (dispatch) => {
    return {
        fetchArticle: (slug) => dispatch(fetchArticle(slug)),
        fetchArticleCleanUp: () => dispatch(fetchArticleCleanUp()),
        fetchArticles: ({ limit, menuName, slug }) =>
            dispatch(fetchArticles({ limit, menuName, slug })),
        fetchArticlesCleanUp: () => dispatch(fetchArticlesCleanUp()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePage);
