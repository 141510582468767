import {
    FETCH_OFFERS_REQUEST,
    FETCH_OFFERS_SUCCESS,
    FETCH_OFFERS_FAILURE,
    FETCH_OFFERS_CLEAN_UP,
    FETCH_OFFERS_FOR_PAGE_REQUEST,
    FETCH_OFFERS_FOR_PAGE_SUCCESS,
    FETCH_OFFERS_FOR_PAGE_FAILURE,
    FETCH_OFFERS_FOR_PAGE_CLEAN_UP,
    UPDATE_OFFERS_PAGE_START_PARAM,
} from '../constants/offersTypes';

const initialState = {
    loading: false,
    offers: [],
    offersForPage: [],
    error: '',
    start: 0,
};

const offersReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_OFFERS_REQUEST:
        case FETCH_OFFERS_FOR_PAGE_REQUEST:
            return {
                ...state,
                loading: true,
            };

        case FETCH_OFFERS_SUCCESS:
            return {
                ...state,
                loading: false,
                offers: action.payload.offers,
                error: '',
            };

        case FETCH_OFFERS_FOR_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                offersForPage: [
                    ...state.offersForPage.filter(
                        (existingOffer) =>
                            !action.payload.offers.some(
                                (newOffer) => newOffer.id === existingOffer.id
                            )
                    ),
                    ...action.payload.offers,
                ],
                error: '',
            };

        case FETCH_OFFERS_FAILURE:
        case FETCH_OFFERS_FOR_PAGE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case FETCH_OFFERS_CLEAN_UP:
        case FETCH_OFFERS_FOR_PAGE_CLEAN_UP:
            return {
                ...state,
                offers: [],
                offersForPage: [],
                loading: false,
            };

        case UPDATE_OFFERS_PAGE_START_PARAM:
            return {
                ...state,
                start: state.start + action.payload,
            };

        default:
            return state;
    }
};

export default offersReducer;
