import {
    FETCH_CALENDAR_REQUEST,
    FETCH_CALENDAR_SUCCESS,
    FETCH_CALENDAR_FAILURE,
} from '../constants/calendarTypes';

const initialState = {
    loading: false,
    content: [], // Updated property name
    error: '',
};

const calendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_CALENDAR_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CALENDAR_SUCCESS:
            return {
                loading: false,
                content: action.payload, // Updated property name
                error: '',
            };
        case FETCH_CALENDAR_FAILURE:
            return {
                loading: false,
                content: [], // Updated property name
                error: action.payload,
            };
        default:
            return state;
    }
};

export default calendarReducer;
