import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FontAwesome from '../uiStyle/FontAwesome';
import SidebarMenu from '../SidebarMenu';
import mockMenu from '../../mockdata/menu.json';

/**
 * MainMenu Component
 * Displays the main navigation menu with a sticky header and a toggleable sidebar menu.
 */
const MainMenu = ({ className }) => {
    // State to control the visibility of the sidebar menu
    const [sideShow, setSideShow] = useState(false);

    return (
        <>
            <div className={`main-menu ${className || ''}`} id="header">
                {/* Back to top button */}
                <Link to="#top" className="up_btn up_btn1">
                    <FontAwesome name="chevron-double-up" />
                </Link>
                <div className="main-nav clearfix is-ts-sticky">
                    <div className="container">
                        <div className="row justify-content-between">
                            <nav className="navbar navbar-expand-lg col-lg-12 align-self-center">
                                <div className="site-nav-inner">
                                    {/* Sidebar toggle button */}
                                    <button
                                        type="button"
                                        className="navbar-toggler"
                                        onClick={() => setSideShow(true)}
                                    >
                                        <FontAwesome name="bars" />
                                    </button>

                                    {/* Sidebar menu */}
                                    <SidebarMenu
                                        sideShow={sideShow}
                                        setSideShow={setSideShow}
                                        menus={mockMenu}
                                    />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainMenu;
