import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import Routes from '../__Routes';
import ScrollTopButton from '../../components/ScrollTopButton';
import ScrollToTop from '../../components/ScrollToTop';
import 'moment/locale/de';

/**
 * App Component
 * - Serves as the root component of the application.
 * - Integrates global features such as routing, toast notifications, and scroll behavior.
 * - Enables Matomo link tracking for analytics.
 *
 * Props:
 * - `error` (string): Error message to display as a toast notification.
 * - `success` (string): Success message to display as a toast notification.
 */
const App = ({ error, success }) => {
    if (error) toast.error(error);
    if (success) toast.success(success);

    const { enableLinkTracking } = useMatomo();
    enableLinkTracking();
    return (
        <>
            <ScrollToTop />
            <Routes />
            <ToastContainer position="top-center" />
            <ScrollTopButton />
        </>
    );
};

export default App;
