import React, { useEffect, useState, useCallback } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { connect } from 'react-redux';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import moment from 'moment';
import {
    fetchArticleHomepage,
    fetchOffers,
    fetchCalendar,
} from '../../store/actions';
import Metadata from '../../components/Metadata';
import { RG_DESCRIPTION } from '../../utils/constants';
import HomePageLayout from '../../components/HomePageLayout';
import fetchSquirrelNews from '../../store/actions/getSquirrelNewsAction';

/**
 * HomePage Component
 * - Displays the homepage with articles, offers, and a calendar.
 * - Integrates analytics tracking with Matomo.
 * - Dynamically fetches content for articles, offers, calendar events, and squirrel news.
 * - Includes a gallery-style layout for content display.
 */
const HomePage = ({
    fetchArticleHomepage,
    fetchOffers,
    mainArticle,
    publisherArticles = [],
    newsArticles,
    latestOffers,
    wissenArticles,
    gtTippsArticles,
    pflegeArticles,
    fetchCalendar,
    calendarContent,
    fetchSquirrelNews,
    squirrelNewsArticles,
}) => {
    useEffect(() => {
        const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');

        fetchArticleHomepage();
        fetchSquirrelNews();
        fetchOffers({ start: 0, limit: 4 });
        fetchCalendar(startOfMonth);
    }, [fetchArticleHomepage, fetchCalendar, fetchOffers, fetchSquirrelNews]);
    const { trackPageView } = useMatomo();
    // Track page view
    useEffect(() => {
        const pageTitle = `Regio Health News - Home Page`;
        const pageUrl = process.env.REACT_APP_BASE_PAGE_URL.replace(/\/+$/, ''); // Ensure no trailing slash

        trackPageView({
            documentTitle: pageTitle,
            href: pageUrl,
        });
    }, []);

    const [articleLimit, setArticleLimit] = useState(7);

    const fetchArticleHomepageHook = useCallback(() => {
        setArticleLimit(articleLimit + 2);
    }, [articleLimit]);

    return (
        <>
            <Metadata
                title="Regionale Gesundheit"
                description={RG_DESCRIPTION}
                url={`${process.env.REACT_APP_BASE_PAGE_URL}`}
            />
            <HomePageLayout
                mainArticle={mainArticle}
                newsArticles={newsArticles}
                articleLimit={articleLimit}
                publisherArticles={publisherArticles}
                latestOffers={latestOffers}
                buttonText="MEHR INHALT"
                fetchArticleHomepageHook={fetchArticleHomepageHook}
                wissenArticles={wissenArticles}
                gtTippsArticles={gtTippsArticles}
                pflegeArticles={pflegeArticles}
                calendarContent={calendarContent}
                squirrelNewsArticles={squirrelNewsArticles}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        mainArticle: state.articles.articlesHomepage.mainArticle,
        publisherArticles: state.articles.articlesHomepage.publisherArticles,
        newsArticles: state.articles.articlesHomepage.newsArticles,
        latestOffers: state.offers.offers,
        wissenArticles: state.articles.articlesHomepage.wissenArticles,
        gtTippsArticles: state.articles.articlesHomepage.gtTippsArticles,
        pflegeArticles: state.articles.articlesHomepage.pflegeArticles,
        calendarContent: state.calendar.content,
        squirrelNewsArticles: state.squirrelNewsArticles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchArticleHomepage: () => dispatch(fetchArticleHomepage()),
        fetchOffers: ({ start, limit }) =>
            dispatch(fetchOffers({ start, limit })),
        fetchCalendar: (startOfMonth, endOfMonth) =>
            dispatch(fetchCalendar(startOfMonth, endOfMonth)),
        fetchSquirrelNews: () => dispatch(fetchSquirrelNews()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
