import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import BannerSection from '../../components/BannerSection';
import Metadata from '../../components/Metadata';

/**
 * CookieDeclarationPage Component
 * - Displays information related to the site's cookie policy.
 * - Integrates Matomo analytics to track page views.
 * - Includes metadata for SEO purposes and a banner section.
 */
const CookieDeclarationPage = () => {
    const { trackPageView } = useMatomo();
    // Track page view
    useEffect(() => {
        const pageTitle = 'Regio Health News - Cookie Declaration';
        const pageUrl = `${process.env.REACT_APP_BASE_PAGE_URL}/cookie-declaration`;

        trackPageView({
            documentTitle: pageTitle,
            href: pageUrl,
        });
    }, []);

    return (
        <>
            <Metadata
                title="Regio Health News - Cookie Declaration"
                description="Cookie Declaration Page"
                url={`${process.env.REACT_APP_BASE_PAGE_URL}/cookie-declaration`}
            />
            <div className="info_page">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="info_content">
                                <div id="cookiefirst-policy-page" />
                                <div>
                                    This cookie policy has been created and
                                    updated by{' '}
                                    <a href="https://cookiefirst.com">
                                        CookieFirst.com
                                    </a>
                                </div>

                                <div className="space-70" />

                                <div id="cookiefirst-cookies-table" />
                                <div>
                                    This cookie table has been created and
                                    updated by the{' '}
                                    <a href="https://cookiefirst.com">
                                        CookieFirst consent management platform
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CookieDeclarationPage;
