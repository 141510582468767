import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Spinner } from 'reactstrap';

import rgOfferPlaceholderSmall from '../../doc/img/dummy_small.png';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './index.scss';

/**
 * ContentList Component
 * Displays news articles with optional squirrel news and publisher articles.
 * - Handles conditional loading for different news sources.
 * - Supports lazy loading for images and a load more button.
 */
const ContentList = ({
    publisherArticles = [],
    articleLimit,
    offer,
    fetchContentHook,
    buttonText,
    squirrelNewsArticles,
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    // Effect to determine the loading state based on current articles and page type
    useEffect(() => {
        const isNewsPage = location.pathname === '/news';
        const hasSquirrelNews =
            isNewsPage && squirrelNewsArticles?.squirrelNews?.length > 0;
        const hasPublisherArticles = publisherArticles?.length > 0;

        if (isNewsPage) {
            setIsLoading(!(hasSquirrelNews && hasPublisherArticles));
        } else {
            setIsLoading(!hasPublisherArticles);
        }
    }, [squirrelNewsArticles, publisherArticles, location.pathname]);

    // Trigger the fetchContentHook to load more articles
    const onClickLoadMore = () => {
        fetchContentHook();
    };

    return (
        <div className="business3 padding20 white_bg border-radious5">
            {isLoading ? (
                // Show spinner while content is loading
                <Spinner
                    className="spinner_custom"
                    animation="border"
                    variant="success"
                />
            ) : (
                <>
                    {location.pathname === '/news' && (
                        <h2 className="partner-text">
                            <span
                                className="section-title"
                                style={{ textTransform: 'none' }}
                            >
                                GOOD NEWS in Medienpartnerschaft mit
                            </span>
                            <a
                                href="https://squirrel-news.net/"
                                target="_blank "
                                className="squirrel-logo-link"
                            >
                                <img
                                    src="https://cdn1-2010d.kxcdn.com/de/wp-content/uploads/sites/2/2019/10/Squirrel_News-Logo-web.jpg"
                                    alt="Squirrel News Logo"
                                />
                            </a>
                        </h2>
                    )}
                    {/* Render Squirrel News articles if on /news page */}
                    {location.pathname === '/news' &&
                    squirrelNewsArticles?.squirrelNews?.length > 0
                        ? squirrelNewsArticles.squirrelNews.map((item) => (
                              <a
                                  key={item.title}
                                  target="_blank"
                                  href={item.slug}
                                  className="cursor_pointer"
                              >
                                  <div className="single_post post_type12 type20">
                                      <div className="post_img">
                                          <div className="img_wrap">
                                              {/* Render article image or a placeholder */}
                                              {item.image ? (
                                                  <img
                                                      className="lazyLoad border-radious5"
                                                      src={item.image}
                                                      alt="thumb"
                                                      effect="blur"
                                                  />
                                              ) : (
                                                  <img
                                                      className="lazyLoad border-radious5"
                                                      src={
                                                          rgOfferPlaceholderSmall
                                                      }
                                                      alt="thumb"
                                                      effect="blur"
                                                  />
                                              )}
                                          </div>
                                      </div>
                                      <div className="single_post_text">
                                          <h3>{item.title}</h3>
                                          <div className="row">
                                              <div className="col-12 align-self-center">
                                                  <div className="meta_col">
                                                      <p>
                                                          {/* Format article date */}
                                                          {moment(
                                                              item.date,
                                                              'DD.MM.YYYY'
                                                          ).format('LL')}
                                                          <img
                                                              src="https://cdn1-2010d.kxcdn.com/de/wp-content/uploads/sites/2/2019/10/Squirrel_News-Logo-web.jpg"
                                                              alt="Squirrel News Logo"
                                                              className="squirrel-logo"
                                                          />
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>
                                          <p className="post-p">
                                              {item.description}
                                          </p>
                                      </div>
                                  </div>
                              </a>
                          ))
                        : ''}
                    {/* Render publisher articles */}
                    {publisherArticles.length > 0
                        ? publisherArticles
                              .slice(0, articleLimit) // Limit the number of articles
                              .map((item, i) => (
                                  <Link
                                      className="cursor_pointer"
                                      key={item.id}
                                      to={`/${
                                          item.type === 'offer'
                                              ? 'offer'
                                              : 'article'
                                      }/${item.slug}`}
                                  >
                                      <div className="single_post post_type12 type20">
                                          <div className="post_img">
                                              <div className="img_wrap">
                                                  {/* Render article image or a placeholder */}
                                                  {item.main_image ? (
                                                      <img
                                                          className="lazyLoad border-radious5"
                                                          src={
                                                              item.main_image
                                                                  .formats
                                                                  ?.small?.url
                                                                  ? `${
                                                                        process
                                                                            .env
                                                                            .REACT_APP_CMS_URL_IMAGE +
                                                                        item
                                                                            .main_image
                                                                            .formats
                                                                            .small
                                                                            .url
                                                                    }`
                                                                  : `${
                                                                        process
                                                                            .env
                                                                            .REACT_APP_CMS_URL_IMAGE +
                                                                        item
                                                                            .main_image
                                                                            .url
                                                                    }`
                                                          }
                                                          alt="thumb"
                                                          effect="blur"
                                                      />
                                                  ) : (
                                                      <img
                                                          className="lazyLoad border-radious5"
                                                          src={
                                                              rgOfferPlaceholderSmall
                                                          }
                                                          alt="thumb"
                                                          effect="blur"
                                                      />
                                                  )}
                                              </div>
                                          </div>
                                          <div className="single_post_text">
                                              <h3>{item.title}</h3>
                                              <div className="row">
                                                  <div className="col-12 align-self-center">
                                                      <div className="meta_col">
                                                          <p>
                                                              {/* Format article publish date */}
                                                              {moment(
                                                                  item.publishAt
                                                              ).format('LL')}
                                                              {offer &&
                                                              item.company
                                                                  ? `, ${item.company}`
                                                                  : ''}
                                                              {offer &&
                                                              item.region
                                                                  ? `, ${item.region}`
                                                                  : ''}
                                                          </p>
                                                      </div>
                                                  </div>
                                              </div>
                                              <p className="post-p">
                                                  {item.description}
                                              </p>
                                          </div>
                                      </div>
                                  </Link>
                              ))
                        : null}
                    {/* Load more button for fetching additional articles */}
                    {publisherArticles.length > 0 && (
                        <button
                            type="button"
                            onClick={onClickLoadMore}
                            className="btn-sm search-button"
                        >
                            {buttonText || 'MEHR'}
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default ContentList;
