import axios from 'axios';
import React, { useEffect, useState } from 'react';

const HorizontalIframeHomepage = () => {
    const [showBanner, setShowBanner] = useState(false);
    const [adBlockEnabled, setAdBlockEnabled] = useState(false);
    const [activeBanner, setActiveBanner] = useState(0);
    const [bannerSrc, setBannerSrc] = useState(
        'https://cm2.regiohealth.net/cma/www/delivery/avw.php?zoneid=8&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=a2b09022'
    );
    const [bannerLink, setBannerLink] = useState(
        'https://cm2.regiohealth.net/cma/www/delivery/ck.php?n=a2b09022&amp;cb=INSERT_RANDOM_NUMBER_HERE'
    );

    useEffect(() => {
        const detectAdBlock = async () => {
            axios
                .get(
                    'https://cm2.regiohealth.net/cma/www/delivery/afr.php?zoneid=8&amp;cb=23423'
                )
                .then((response) => {})
                .catch((error) => {
                    setAdBlockEnabled(true);
                });
        };

        detectAdBlock();
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            const randomNumber = Math.floor(Math.random() * 1000000);
            const newBannerSrc = `https://cm2.regiohealth.net/cma/www/delivery/avw.php?zoneid=8&amp;cb=${randomNumber}&amp;n=a2b09022`;
            const newBannerLink = `https://cm2.regiohealth.net/cma/www/delivery/ck.php?n=a2b09022&amp;cb=${randomNumber}`;

            setShowBanner(false);

            setBannerSrc(newBannerSrc);
            setBannerLink(newBannerLink);
        }, 30000);

        return () => clearTimeout(timer);
    }, [bannerSrc, bannerLink]);

    const banners = [
        {
            id: 1,
            src:
                'https://cm2.regiohealth.net/cma/www/delivery/avw.php?zoneid=8&amp;cb=12345&amp;n=a2b09022',
            link:
                'https://cm2.regiohealth.net/cma/www/delivery/ck.php?n=a2b09022&amp;cb=12345',
        },
        {
            id: 2,
            src:
                'https://cm2.regiohealth.net/cma/www/delivery/avw.php?zoneid=8&amp;cb=67890&amp;n=a2b09022',
            link:
                'https://cm2.regiohealth.net/cma/www/delivery/ck.php?n=a2b09022&amp;cb=67890',
        },
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveBanner((prevBanner) =>
                prevBanner === banners.length - 1 ? 0 : prevBanner + 1
            );
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div id="ad">
            <iframe
                title="homepage-horizontal"
                id="ac108c94"
                name="ac108c94"
                src={`https://cm2.regiohealth.net/cma/www/delivery/afr.php?zoneid=8&amp;cb=${Math.floor(
                    Math.random() * 1000000
                )}`}
                frameBorder="0"
                scrolling="no"
                width="892"
                height="110"
                allow="autoplay"
            >
                <a href={bannerLink} target="_blank">
                    <img src={bannerSrc} border="0" alt="" />
                </a>
            </iframe>
        </div>
    );
};

export default HorizontalIframeHomepage;
