import React from 'react';
import PropTypes from 'prop-types';
import FooterCopyright from '../FooterCopyright';
import mockPages from '../../mockdata/pages.json';
import 'react-lazy-load-image-component/src/effects/blur.css';

/**
 * FooterArea Component
 * Displays the footer with partner logos, descriptive text, and copyright information.
 */
const FooterArea = ({ className }) => {
    // Mock data for footer menu links
    const pagesMenu = mockPages;

    return (
        <div className={`footer footer_area1 ${className || ''}`}>
            <div className="container">
                <div className="cta">
                    <div className="row">
                        <div className="col-12">
                            {/* Partner logos section */}
                            <div className="partner_logos">
                                <ul className="inline">
                                    <li>
                                        <a
                                            href="https://www.regiohealth.net/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <img
                                                alt="logo"
                                                className="gt_logo"
                                                src={`${process.env.REACT_APP_CMS_URL_IMAGE}/uploads/rh_network_logo_color_2_faf21302dd.png`}
                                                effect="blur"
                                            />
                                        </a>
                                    </li>
                                </ul>
                                <div className="space-20" />
                                {/* Descriptive text about RegioHealth */}
                                <div
                                    style={{
                                        color: '#5b5b5b',
                                    }}
                                >
                                    RegioHealth.news ist das Gesundheitsportal
                                    des{' '}
                                    <a
                                        href="https://www.regiohealth.net"
                                        target="_blank"
                                        style={{ color: '#5b5b5b' }}
                                    >
                                        RegioHealth Netzwerks
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer copyright and additional links */}
            <FooterCopyright pagesMenu={pagesMenu} />
        </div>
    );
};

// PropTypes for the component
FooterArea.propTypes = {
    className: PropTypes.string,
};

// Default props for the component
FooterArea.defaultProps = {
    className: '',
};

export default FooterArea;
