import React from 'react';

/**
 * FontAwesome Component
 * Renders a FontAwesome icon with optional size and additional attributes.
 *
 * Props:
 * - `name` (string): The name of the FontAwesome icon (e.g., "user", "plus").
 * - `size` (string): Optional size class for the icon (e.g., "lg", "2x").
 * - `...rest`: Additional props to pass to the `<i>` element (e.g., styles, event handlers).
 */
const FontAwesome = ({ name = '', size, ...rest }) => {
    let sizeClass = '';

    // Map size prop to FontAwesome size classes
    if (size === 'lg') {
        sizeClass = 'fa-lg';
    } else if (size === '2x') {
        sizeClass = 'fa-2x';
    }

    return (
        // Spread additional props and apply computed size class
        // eslint-disable-next-line react/jsx-props-no-spreading
        <i id="myIcon" {...rest} className={`fa fa-${name} ${sizeClass}`} />
    );
};

export default FontAwesome;
