import axios from 'axios';
import {
    FETCH_SQUIRREL_NEWS_FAILURE,
    FETCH_SQUIRREL_NEWS_REQUEST,
    FETCH_SQUIRREL_NEWS_SUCCESS,
} from '../constants/squirrelNewsTypes';

const fetchSquirrelNewsRequest = () => {
    return {
        type: FETCH_SQUIRREL_NEWS_REQUEST,
    };
};

const fetchSquirrelNewsSuccess = (squirrelNews) => {
    return {
        type: FETCH_SQUIRREL_NEWS_SUCCESS,
        payload: squirrelNews,
    };
};

const fetchSquirrelNewsFailure = (error) => {
    return {
        type: FETCH_SQUIRREL_NEWS_FAILURE,
        payload: error,
    };
};

const fetchSquirrelNews = () => {
    return (dispatch) => {
        dispatch(fetchSquirrelNewsRequest);
        axios
            .get(
                `https://getlatestheadlines-mdlzfg63qq-uc.a.run.app/?num=3&language=de`,
                {
                    headers: { token: process.env.REACT_APP_SQUIRREL_TOKEN },
                }
            )
            .then((response) => {
                const categories = response.data;
                dispatch(fetchSquirrelNewsSuccess(categories));
            })
            .catch((error) => {
                const errorMsg = error.message;
                dispatch(fetchSquirrelNewsFailure(errorMsg));
            });
    };
};

export default fetchSquirrelNews;
