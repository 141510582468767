import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
// eslint-disable-next-line camelcase
import { atcb_action } from 'add-to-calendar-button';
import moment from 'moment';
import FontAwesome from '../uiStyle/FontAwesome';
import xLogo from '../../doc/img/twitter-x.svg';

/**
 * Socials Component
 * - Displays social sharing links and a calendar button for the provided content.
 * - Supports Twitter, Facebook, WhatsApp, Telegram, LinkedIn, and Email sharing.
 * - Allows adding events to a calendar (Google, Apple, Outlook.com).
 */
const Socials = ({ content, type }) => {
    const [calendarConfig, setCalendarConfig] = useState({});

    useEffect(() => {
        // Configure calendar options if content has calendar data
        if (content && content.calendar && content.calendar.length > 0) {
            setCalendarConfig({
                name: content.title,
                description: content.description,
                startDate: content.calendar[0].startDate,
                endDate: content.calendar[0].endDate,
                startTime:
                    content.calendar[0].startTime && content.calendar[0].endTime
                        ? moment(
                              content.calendar[0].startTime,
                              'HH:mm:ss'
                          ).format('HH:mm')
                        : null,
                endTime:
                    content.calendar[0].endTime && content.calendar[0].startTime
                        ? moment(
                              content.calendar[0].endTime,
                              'HH:mm:ss'
                          ).format('HH:mm')
                        : null,
                options: ['Google', 'Apple', 'Outlook.com'],
                timeZone: 'Europe/Berlin',
                buttonStyle: 'round',
                listStyle: 'overlay',
                lightMode: 'bodyScheme',
                hideBackground: 'true',
            });
        }
    }, [content]);

    const button = document.getElementById('calendarButton');

    return (
        <div className="author_social inline">
            {/* Tooltip for social icons */}
            <ReactTooltip place="top" type="dark" effect="solid" />

            {/* Social Sharing Links */}
            <ul>
                {/* Twitter/X */}
                <li>
                    <a
                        data-tip="Twitter"
                        href={`https://x.com/share?url=${process.env.REACT_APP_BASE_PAGE_URL}/${type}/${content.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img src={xLogo} alt="Twitter/X" />
                    </a>
                </li>

                {/* Calendar Button */}
                {content &&
                    content.slug &&
                    content.calendar &&
                    content.calendar.length > 0 && (
                        <li>
                            <div
                                data-tip="Kalendar"
                                id="calendarButton"
                                className="calendarButton"
                                type="button"
                                onClick={() =>
                                    atcb_action(calendarConfig, button)
                                }
                            >
                                <FontAwesome name=" fa-calendar-plus-o" />
                            </div>
                        </li>
                    )}

                {/* Facebook */}
                <li>
                    <a
                        data-tip="Facebook"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_BASE_PAGE_URL}/${type}/${content.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesome name="facebook-f" />
                    </a>
                </li>

                {/* WhatsApp */}
                <li>
                    <a
                        data-tip="Whatsapp"
                        href={`https://wa.me/?text=${process.env.REACT_APP_BASE_PAGE_URL}/${type}/${content.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesome name="whatsapp" />
                    </a>
                </li>

                {/* Telegram */}
                <li>
                    <a
                        data-tip="Telegram"
                        href={`https://t.me/share/url?url=${process.env.REACT_APP_BASE_PAGE_URL}/${type}/${content.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesome name="telegram" />
                    </a>
                </li>

                {/* LinkedIn */}
                <li>
                    <a
                        data-tip="LinkedIn"
                        rel="noopener noreferrer"
                        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                            `${process.env.REACT_APP_BASE_PAGE_URL}/${type}/${content.slug}`
                        )}`}
                        target="_blank"
                    >
                        <FontAwesome name="linkedin" />
                    </a>
                </li>

                {/* Email */}
                <li>
                    <a
                        data-tip="Email"
                        href={`mailto:?subject=Interessanter Artikel! ${content.title}&body=${content.title}%0D%0A%0D%0A${content.description}%0D%0A%0D%0A${process.env.REACT_APP_BASE_PAGE_URL}/${type}/${content.slug}`}
                        target="_blank"
                    >
                        <FontAwesome name="envelope" />
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default Socials;
