import Axios from 'axios';
import React, { useEffect, useState } from 'react';

const HorizontalIframeAngebote = () => {
    const [adBlockEnabled, setAdBlockEnabled] = useState(false);
    useEffect(() => {
        const detectAdBlock = async () => {
            Axios.get(
                'https://cm2.regiohealth.net/cma/www/delivery/afr.php?zoneid=8&amp;cb=23423'
            )
                .then((response) => {})
                .catch((error) => {
                    setAdBlockEnabled(true);
                });
        };

        detectAdBlock();
    }, []);

    if (adBlockEnabled) {
        return null; // Don't render anything if ads are blocked
    }
    return (
        <div className="banner2 border-radious5 banner_horizontal mb20 mt20">
            <iframe
                title="angebote-horizontal"
                id="a199bbe6"
                name="a199bbe6"
                src="https://cm2.regiohealth.net/cma/www/delivery/afr.php?zoneid=6&amp;target=_blank&amp;cb=INSERT_RANDOM_NUMBER_HERE"
                frameBorder="0"
                scrolling="no"
                width="892"
                height="110"
                allow="autoplay"
            >
                <a
                    href="https://cm2.regiohealth.net/cma/www/delivery/ck.php?n=adbda12a&amp;cb=INSERT_RANDOM_NUMBER_HERE"
                    target="_blank"
                >
                    <img
                        src="https://cm2.regiohealth.net/cma/www/delivery/avw.php?zoneid=6&amp;cb=INSERT_RANDOM_NUMBER_HERE&amp;n=adbda12a"
                        border="0"
                        alt=""
                    />
                </a>
            </iframe>
        </div>
    );
};

export default HorizontalIframeAngebote;
