import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchGeoData, searchByKeyword } from '../../store/actions';
import Dropdown from '../Dropdown';
import mockMenu from '../../mockdata/menu.json';

/**
 * LogoArea Component
 * Displays the logo and navigation menu area.
 * - Logo redirects to the homepage on click.
 * - Renders a dropdown menu using mock data.
 */
const LogoArea = ({ history }) => {
    return (
        <div className="logo_area white_bg">
            <div className="container">
                <div className="row justify-content-between">
                    {/* Logo Section */}
                    <div className="col-sm-12 col-md-4 col-lg-3 col-xl-3 align-self-center">
                        <div className="logo" onClick={() => history.push('/')}>
                            <img
                                alt="regio health logo"
                                src={`${process.env.REACT_APP_CMS_URL_IMAGE}/uploads/regiohealth_news_16cb0d731e.svg`}
                            />
                        </div>
                    </div>
                    {/* Navigation Menu */}
                    <div className="col-9 align-self-center main-nav">
                        <nav className="navbar navbar-expand-lg">
                            <div className="site-nav-inner">
                                <div
                                    id="navbarSupportedContent"
                                    className="justify-content-end collapse navbar-collapse navbar-responsive-collapse"
                                >
                                    <ul className="nav navbar-nav" id="scroll">
                                        {/* Render Dropdown with menu items */}
                                        {mockMenu.length > 0 && (
                                            <Dropdown menus={mockMenu} />
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

/**
 * Maps dispatch functions to props.
 * - `fetchGeoData`: Fetches geographical data based on filters.
 * - `searchByKeyword`: Performs a search based on keywords.
 */
const mapDispatchToProps = (dispatch) => {
    return {
        fetchGeoData: (filters) => dispatch(fetchGeoData(filters)),
        searchByKeyword: (filters) => dispatch(searchByKeyword(filters)),
    };
};

// Connect component to Redux and enable routing props
export default connect(null, mapDispatchToProps)(withRouter(LogoArea));
