import React from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { isMobileOnly, isTablet } from 'react-device-detect';
import rgOfferPlaceholderThumbnail from '../../doc/img/dummy_thumbnail.png';

/**
 * OffersSidebar Component
 * Displays a list of most-viewed offers.
 * Includes a "Load More Offers" button for mobile and tablet devices.
 */
const OffersSidebar = ({ title, contentData }) => {
    const history = useHistory();

    // Navigate to the full offers page
    const handleClick = () => {
        history.push('/angebote');
    };

    return (
        <div className="most_widget3 padding20 white_bg border-radious5 mb30 sm-mt30">
            {/* Section Heading */}
            <div className="heading">
                <h2 className="widget-title">{title || ''}</h2>
            </div>
            <div className="space-20" />

            {/* Offers List */}
            <div className="post_type2_carousel multipleRowCarousel pt12_wrapper nav_style1">
                {contentData.map((item) => {
                    // Only display offers that haven't expired
                    if (item.end > moment().format('YYYY-MM-DD')) {
                        return (
                            <div
                                key={item.id}
                                className="single_post widgets_small type8 type17"
                            >
                                {/* Offer Image */}
                                <div className="post_img">
                                    <div className="img_wrap_2">
                                        <Link to={`/offer/${item.slug}`}>
                                            <img
                                                className="lazyLoad crop_image"
                                                src={
                                                    item.main_image
                                                        ? `${
                                                              process.env
                                                                  .REACT_APP_CMS_URL_IMAGE
                                                          }${
                                                              item.main_image
                                                                  .formats
                                                                  ?.thumbnail
                                                                  ?.url ||
                                                              item.main_image
                                                                  .url
                                                          }`
                                                        : rgOfferPlaceholderThumbnail
                                                }
                                                alt="thumb"
                                            />
                                        </Link>
                                    </div>
                                </div>
                                {/* Offer Details */}
                                <div className="single_post_text">
                                    <h4>
                                        <Link to={`/offer/${item.slug}`}>
                                            {item.title}
                                        </Link>
                                    </h4>
                                    <div className="meta2">
                                        <p>
                                            {moment(item.publishAt).format(
                                                'LL'
                                            )}
                                        </p>
                                    </div>
                                    <div className="space-10" />
                                </div>
                            </div>
                        );
                    }
                    return null;
                })}
            </div>

            {/* "Load More Offers" Button for Mobile/Tablet */}
            {(isMobileOnly || isTablet) && (
                <>
                    <div className="space-10" />
                    <button
                        type="button"
                        onClick={handleClick}
                        className="btn-sm search-button"
                    >
                        MEHR ANGEBOTE
                    </button>
                </>
            )}
        </div>
    );
};

export default OffersSidebar;
