import React, { useState, useEffect } from 'react';
import FontAwesome from '../uiStyle/FontAwesome';

/**
 * ScrollTopButton Component
 * Displays a button that becomes visible after scrolling down 200px
 * and scrolls the page smoothly back to the top when clicked.
 */
const ScrollTopButton = () => {
    const [sticky, setSticky] = useState('');

    // Effect to toggle the visibility of the button based on scroll position
    useEffect(() => {
        const handleScroll = () => {
            if (Math.ceil(window.scrollY) >= 200) {
                setSticky('sticky');
            } else {
                setSticky('');
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll); // Cleanup
    }, []);

    // Function to scroll to the top of the page smoothly
    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <div className={sticky}>
            {/* Button to scroll back to the top */}
            <div className="up_btn up_btn1" onClick={scrollTop}>
                <FontAwesome name="angle-double-up" />
            </div>
        </div>
    );
};

export default ScrollTopButton;
