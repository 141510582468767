import React from 'react';
import { NavLink } from 'react-router-dom';
import FontAwesome from '../uiStyle/FontAwesome';
import 'react-lazy-load-image-component/src/effects/blur.css';

/**
 * Dropdown Component
 * Renders a list of navigation menu items with specific handling for "suchportal" and "angebote".
 */
const Dropdown = ({ menus }) => {
    // Extract the "suchportal" item and separate it from other menu items
    const suchportalItem = menus.find((item) => item.linkText === 'suchportal');
    const otherItems = menus.filter((item) => item.linkText !== 'suchportal');

    return (
        <>
            {/* Render all menu items except "suchportal" */}
            {otherItems.map((item) => {
                let { linkText } = item;
                const icon = null;

                // Add an icon to the "angebote" menu item
                if (linkText === 'angebote') {
                    linkText = (
                        <>
                            {linkText}
                            <img
                                className="angebote-icon"
                                src="https://cms.gesundheitsticket.de/uploads/icon_app_25b83c377e.png"
                                alt="icon"
                            />
                        </>
                    );
                }

                return (
                    <li key={item.id} className="nav-item">
                        <NavLink
                            exact={item.link === '/'} // Ensure exact match for the home link
                            to={item.link}
                            className="menu-dropdown nav-menu-link text-decoration-none"
                            data-toggle="dropdown"
                        >
                            {icon || linkText}
                        </NavLink>
                    </li>
                );
            })}
            {/* Render the "suchportal" menu item with a search icon */}
            {suchportalItem && (
                <li key={suchportalItem.id} className="nav-item">
                    <NavLink
                        to={suchportalItem.link}
                        className="menu-dropdown nav-menu-link text-decoration-none"
                        data-toggle="dropdown"
                    >
                        <FontAwesome name="search" size="lg" />
                    </NavLink>
                </li>
            )}
        </>
    );
};

export default Dropdown;
