import axios from 'axios';
import qs from 'qs';
import {
    FETCH_OFFERS_REQUEST,
    FETCH_OFFERS_SUCCESS,
    FETCH_OFFERS_FAILURE,
    FETCH_OFFERS_CLEAN_UP,
    FETCH_OFFERS_FOR_PAGE_REQUEST,
    FETCH_OFFERS_FOR_PAGE_SUCCESS,
    FETCH_OFFERS_FOR_PAGE_FAILURE,
    FETCH_OFFERS_FOR_PAGE_CLEAN_UP,
    UPDATE_OFFERS_PAGE_START_PARAM,
} from '../constants/offersTypes';

const fetchOffersRequest = () => ({ type: FETCH_OFFERS_REQUEST });

const fetchOffersSuccess = (payload) => ({
    type: FETCH_OFFERS_SUCCESS,
    payload,
});

const fetchOffersFailure = (error) => ({
    type: FETCH_OFFERS_FAILURE,
    payload: error,
});

export const fetchOffersCleanUp = () => ({
    type: FETCH_OFFERS_CLEAN_UP,
});

const fetchOffersForPageRequest = () => ({
    type: FETCH_OFFERS_FOR_PAGE_REQUEST,
});

const fetchOffersForPageSuccess = (payload) => ({
    type: FETCH_OFFERS_FOR_PAGE_SUCCESS,
    payload,
});

const fetchOffersForPageFailure = (error) => ({
    type: FETCH_OFFERS_FOR_PAGE_FAILURE,
    payload: error,
});

export const fetchOffersForPageCleanUp = () => ({
    type: FETCH_OFFERS_FOR_PAGE_CLEAN_UP,
});

export const updateOffersPageStartParam = (increment = 6) => ({
    type: UPDATE_OFFERS_PAGE_START_PARAM,
    payload: increment,
});

export const fetchOffers = ({ categories = [], city, start, limit } = {}) => {
    return (dispatch) => {
        const categoriesList = categories.map((item) => item.id);
        const query = qs.stringify(
            { _where: { 'categories.id': categoriesList } },
            { encode: false, arrayFormat: 'repeat' }
        );

        const url = `${
            process.env.REACT_APP_CMS_URL
        }/contents/published?sort=publishAt%3Adesc&filters[type][$eq]=offer${
            categories.length > 0 ? `&${query}` : ''
        }${city ? `&filters[city][$eq]=${city}` : ''}${
            start ? `&pagination[start]=${start}` : ''
        }${limit ? `&pagination[limit]=${limit}` : ''}`;

        dispatch(fetchOffersRequest());
        axios
            .get(url)
            .then((response) => {
                const offers = response.data;
                dispatch(fetchOffersSuccess({ offers, limit }));
            })
            .catch((error) => {
                dispatch(fetchOffersFailure(error.message));
            });
    };
};

// export const fetchOffersForPage = ({
//     categories = [],
//     city,
//     start,
//     limit,
//     slug,
// } = {}) => {
//     return (dispatch) => {
//         const categoriesList = categories.map((item) => item.id);
//         const query = qs.stringify(
//             { _where: { 'categories.id': categoriesList } },
//             { encode: false, arrayFormat: 'repeat' }
//         );

//         const url = `${
//             process.env.REACT_APP_CMS_URL
//         }/contents/published?sort=publishAt%3Adesc&filters[type][$eq]=offer${
//             categories.length > 0 ? `&${query}` : ''
//         }${city ? `&filters[city][$eq]=${city}` : ''}${
//             start ? `&pagination[start]=${start}` : ''
//         }${limit ? `&pagination[limit]=${limit}` : ''}${
//             slug ? `&filters[slug][$ne]=${slug}` : ''
//         }`;

//         dispatch(fetchOffersForPageRequest());
//         axios
//             .get(url)
//             .then((response) => {
//                 const offers = response.data;
//                 dispatch(fetchOffersForPageSuccess({ offers, limit, start }));
//             })
//             .catch((error) => {
//                 dispatch(fetchOffersForPageFailure(error.message));
//             });
//     };
// };
export const fetchOffersForPage = ({
    categories = [],
    city = undefined,
    start = 0, // Offset for pagination
    limit = 6, // Number of items to fetch
} = {}) => {
    return (dispatch) => {
        // Build query string
        const categoriesList = categories.map((item) => item.id);
        const query = qs.stringify(
            {
                _where: { 'categories.id': categoriesList },
            },
            { encode: false, arrayFormat: 'repeat' }
        );

        // Construct URL
        const url = `${
            process.env.REACT_APP_CMS_URL
        }/contents/published?sort=publishAt:desc,id:asc&filters[type][$eq]=offer${
            categories.length > 0 ? `&${query}` : ''
        }${
            city ? `&filters[city][$eq]=${city}` : ''
        }&pagination[start]=${start}&pagination[limit]=${limit}`;

        // Dispatch request
        dispatch(fetchOffersForPageRequest());
        axios
            .get(url)
            .then((response) => {
                const offers = response.data;
                dispatch(fetchOffersForPageSuccess({ offers, limit }));
            })
            .catch((error) => {
                dispatch(fetchOffersForPageFailure(error.message));
            });
    };
};
