import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import moment from 'moment';
import { isMobileOnly } from 'react-device-detect';
import {
    fetchOffer,
    fetchOfferCleanUp,
    fetchOffersForPage,
    fetchOffersForPageCleanUp,
} from '../../store/actions';
import FontAwesome from '../../components/uiStyle/FontAwesome';
import rgOfferPlaceholderMedium from '../../doc/img/dummy_medium.png';
import Metadata from '../../components/Metadata';
import 'react-lazy-load-image-component/src/effects/blur.css';
import AdserverLeaderboard from '../../components/AdserverLeaderboard';
import LatestContent from '../../components/LatestContent';
import Socials from '../../components/Socials';

// Replaces href attributes with target="_blank" for opening links in a new tab
// Also removes unnecessary attributes from <img> tags
const replaceContent = (data) => {
    let content = data.replace(/href/g, "target='_blank' href");

    content = content.replace(/<img[^>]+>/g, (imgTag) => {
        const newImgTag = imgTag
            .replace(/\s+srcset="[^"]*"/g, '')
            .replace(/\s+sizes="[^"]*"/g, '');
        return newImgTag;
    });

    return content;
};

// Ensures a URL starts with "http://" or "https://" for proper linking
const addhttp = (url) => {
    if (!/^(?:f|ht)tps?:\/\//.test(url)) {
        const newUrl = `http://${url}`;
        return newUrl;
    }
    return url;
};

// Determines the meta description for SEO, using either the description or the first category name
const getMetaDescription = (description, categories) => {
    let metaDescription = '';
    if (description) {
        metaDescription = description;
    } else if (categories && categories.length > 0) {
        metaDescription = categories[0].name;
    }
    return metaDescription;
};

// Retrieves the dimensions of an image asynchronously and sets them in state
const getMeta = (url, width, setWidth, height, setHeight) => {
    const img = new Image();
    img.addEventListener('load', function () {
        setWidth(this.naturalWidth);
        setHeight(this.naturalHeight);
    });
    img.src = url;
    return { width, height };
};

// Generates a user-friendly label for the offer's service availability
const serviceLabel = (service) => {
    const label = [];
    if (service.validForTicket) {
        label.push('GesundheitsTicket');
    }
    if (service.validForTicketPlus) {
        label.push('GesundheitsTicket Plus');
    }
    if (service.validForVoucher) {
        label.push('GesundheitsTicket Gutschein');
    }
    if (service.validForDRV) {
        label.push('GesundheitsTicket der DRV');
    }
    if (service.validForHealthVoucher) {
        label.push('HealthVoucher');
    }
    if (label.length > 0) {
        return `Das Angebot ist bezahlbar mit: ${label.join(', ')}`;
    }
    return '';
};

/**
 * OfferPage Component
 * - Displays detailed information about a specific offer.
 * - Fetches the offer data based on the URL slug and cleans up on unmount.
 * - Includes metadata for SEO purposes, related content, and social sharing options.
 * - Dynamically fetches additional offers for display.
 * - Uses Matomo for tracking page views.
 */
const OfferPage = ({
    offerData,
    fetchOffer,
    fetchOfferCleanUp,
    latestOffers = [],
}) => {
    const { slug } = useParams();

    useEffect(() => {
        fetchOffer(slug);
        return () => fetchOfferCleanUp();
    }, [fetchOffer, fetchOfferCleanUp, slug]);

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const offer = offerData.offer || null;

    const { trackPageView } = useMatomo();
    useEffect(() => {
        if (offerData?.offer) {
            const { title, slug } = offerData.offer;

            // Ensure title and slug are defined to avoid runtime errors
            if (title && slug) {
                trackPageView({
                    documentTitle: `Regio Health News - ${title}`,
                    href: `${process.env.REACT_APP_BASE_PAGE_URL}/offer/${slug}`,
                });
            }
        }
    }, [offerData?.offer]);

    return (
        offer && (
            <>
                <Metadata
                    title={offer.title}
                    description={getMetaDescription(
                        offer.description,
                        offer.categories
                    )}
                    image={
                        // eslint-disable-next-line no-nested-ternary
                        offer.main_image &&
                        offer.main_image.formats &&
                        offer.main_image.formats.small
                            ? process.env.REACT_APP_CMS_URL_IMAGE +
                              offer.main_image.formats.small.url
                            : offer.main_image
                            ? process.env.REACT_APP_CMS_URL_IMAGE +
                              offer.main_image.url
                            : rgOfferPlaceholderMedium
                    }
                    imageSize={
                        // eslint-disable-next-line no-nested-ternary
                        offer.main_image &&
                        offer.main_image.formats &&
                        offer.main_image.formats.small
                            ? getMeta(
                                  process.env.REACT_APP_CMS_URL_IMAGE +
                                      offer.main_image.formats.small.url,
                                  width,
                                  setWidth,
                                  height,
                                  setHeight
                              )
                            : offer.main_image
                            ? getMeta(
                                  offer.main_image,
                                  width,
                                  setWidth,
                                  height,
                                  setHeight
                              )
                            : getMeta(
                                  rgOfferPlaceholderMedium,
                                  width,
                                  setWidth,
                                  height,
                                  setHeight
                              )
                    }
                    url={`${process.env.REACT_APP_BASE_PAGE_URL}/offer/${slug}`}
                />
                <div className="archives post post1">
                    <div className="space-20" />
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-10 col-lg-8 m-auto">
                                <div className="single_post_heading">
                                    <h1>{offer.title}</h1>
                                </div>
                                <div className="space-20" />
                                <div className="single_post_description">
                                    <p>{offer.description}</p>
                                </div>
                                <div className="img_wrap">
                                    <img
                                        src={
                                            offer.main_image
                                                ? `${
                                                      process.env
                                                          .REACT_APP_CMS_URL_IMAGE +
                                                      offer.main_image.url
                                                  }`
                                                : rgOfferPlaceholderMedium
                                        }
                                        alt="thumb"
                                        effect="blur"
                                        className="border-radious7"
                                    />
                                </div>
                                <div className="space-20" />

                                <div className="row">
                                    <div className="col-12 col-lg-6">
                                        <Socials content={offer} type="offer" />
                                    </div>
                                    <div className="col-12 col-lg-6 text-left text-lg-right">
                                        <div className="author">
                                            {offer.profile &&
                                            offer.profile.website ? (
                                                <a
                                                    target="_blank"
                                                    href={
                                                        offer.profile &&
                                                        offer.profile.website
                                                            ? addhttp(
                                                                  offer.profile
                                                                      .website
                                                              )
                                                            : '#'
                                                    }
                                                >
                                                    {offer.author ||
                                                        offer.profile.name}
                                                    {offer.profile &&
                                                    offer.profile.company
                                                        ? ` / ${offer.profile.company}`
                                                        : ''}
                                                </a>
                                            ) : (
                                                <p className="company-no-link">
                                                    {offer.author}
                                                    {offer.profile &&
                                                    offer.profile.company
                                                        ? ` / ${offer.profile.company}`
                                                        : ''}
                                                </p>
                                            )}
                                            <ul>
                                                <li>
                                                    {moment(
                                                        offer.publishAt
                                                    ).format('LL')}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-20" />
                                <div className="single_post_info">
                                    <ul>
                                        {offer.offer_info &&
                                            offer.offer_info.price && (
                                                <li>
                                                    Preis:{' '}
                                                    {offer.offer_info.price}
                                                    {' €'}
                                                </li>
                                            )}
                                        {offer.offer_info &&
                                            offer.offer_info.start && (
                                                <li>
                                                    Start:{' '}
                                                    {moment(
                                                        offer.offer_info.start
                                                    ).format('LL')}
                                                </li>
                                            )}
                                        {offer.offer_info &&
                                            offer.offer_info.end && (
                                                <li>
                                                    Ende:{' '}
                                                    {moment(
                                                        offer.offer_info.end
                                                    ).format('LL')}
                                                </li>
                                            )}
                                        {offer.region && (
                                            <li>Bundesland: {offer.region}</li>
                                        )}
                                    </ul>
                                </div>

                                <div className="single_post_content">
                                    {offer && offer.content && (
                                        <div
                                            // eslint-disable-next-line react/no-danger
                                            dangerouslySetInnerHTML={{
                                                __html: replaceContent(
                                                    offer.content
                                                ),
                                            }}
                                        />
                                    )}
                                </div>
                                {offer && offer.offer_info && (
                                    <div className="single_post_content">
                                        {offer.offer_info.key_data && (
                                            <>
                                                <hr />
                                                <div
                                                    // eslint-disable-next-line react/no-danger
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceContent(
                                                            offer.offer_info
                                                                .key_data
                                                        ),
                                                    }}
                                                />
                                            </>
                                        )}

                                        {offer.offer_info.sign_up_info && (
                                            <>
                                                <hr />
                                                <div
                                                    // eslint-disable-next-line react/no-danger
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceContent(
                                                            offer.offer_info
                                                                .sign_up_info
                                                        ),
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                )}
                                {offer.offer_info.service &&
                                    serviceLabel(offer.offer_info.service) !==
                                        '' && (
                                        <div>
                                            <hr />
                                            {serviceLabel(
                                                offer.offer_info.service
                                            )}
                                        </div>
                                    )}
                                {offer.documents && (
                                    <div className="documents">
                                        <ul className="none">
                                            {offer.documents.data.map((doc) => (
                                                <li key={doc.id}>
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            process.env
                                                                .REACT_APP_CMS_URL +
                                                            doc.url
                                                        }
                                                    >
                                                        <FontAwesome name="file-text" />
                                                        <span>{doc.name}</span>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="space-50" />
                        {latestOffers.length > 0 && (
                            <div className="row">
                                <div className="col-12 col-md-10 col-lg-8 m-auto">
                                    <LatestContent
                                        contentData={latestOffers}
                                        type="offer"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {!isMobileOnly && <AdserverLeaderboard />}
            </>
        )
    );
};

const mapStateToProps = (state) => {
    return {
        offerData: state.offer,
        latestOffers: state.offers.offersForPage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOffer: (slug) => dispatch(fetchOffer(slug)),
        fetchOfferCleanUp: () => dispatch(fetchOfferCleanUp()),
        fetchOffersForPage: ({ limit, slug }) =>
            dispatch(fetchOffersForPage({ limit, slug })),
        fetchOffersForPageCleanUp: () => dispatch(fetchOffersForPageCleanUp()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferPage);
